<template>
  <div id="phases" class="phases-section sections-padding-y menu-anchor">
    <div class="container">
      <div class="content-type-2">
        <div 
          :class="[
            'd-flex align-center',
            { 'flex-column': !isTablet }
          ]"
        >
          <div>
            <div class="phase-1 mb-25">
              <h3 class="title-section text-body-2 white--text font-weight-bold">
                {{ $t('phases_phase_1_title') }}
              </h3>
              <p class="text-body-5 white--text">
                {{ $t('phases_phase_1_content') }}
              </p>
            </div>
            <div class="phase-2">
              <h3 class="title-section text-body-2 white--text font-weight-bold">
                {{ $t('phases_phase_2_title') }}
              </h3>
              <p class="text-body-5 white--text">
                {{ $t('phases_phase_2_content_1') }}
              </p>
              <p class="text-body-5 white--text">
                {{ $t('phases_phase_2_content_2') }}
              </p>
            </div>
          </div>
          <div 
            :class="[
              'relative',
              {'mt-5': !isTablet}
            ]"
          >
            <VImg
              class="phones relative"
              :src="isMobile ? 'phasesImageMobile.png' : 'phasesImage.png'"
              :width="isMobile ? '303' : '560'"
              :height="isMobile ? '317' : '532'"
            />
            <VImg
              class="planets absolute"
              :src="isMobile ? 'phasesPlanetsMobile.svg' : 'phasesPlanets.svg'"
              :width="isMobile ? '327' : '580'"
              :height="isMobile ? '356' : '713'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./phases-section"></script>

<style lang="scss" scoped>
.phases-section {
  background-color: transparent;
}
.content-type-2 {
  padding-right: 0px;
  border: 2px solid $white;
}
.text-body-5 {
  position: relative;
  z-index: 2;
  line-height: 21px;
}
.phase-2 {
  .text-body-5:last-of-type {
    margin-top: 21px;
  }
}
.phones {
  z-index: 2;
}
.planets {
  top: -70px;
  left: -33px;
}
@media only screen and (max-width: $md) {
  .content-type-2 {
    padding-right: 80px;
  }
  .planets {
    position: absolute;
    top: -141px;
    left: -15px;
  }
}
@media only screen and (max-width: $xs) {
  .content-type-2 {
    padding-right: 20px;
  }
}
</style>