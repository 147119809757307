import router from './router'
import { createApp } from 'vue'
import store from './store'
import App from './App.vue'

import axiosMixin from './plugins/axios'
import vueMixin from './plugins/vueMixin'
import Notifications from '@kyvg/vue3-notification'

import '@/assets/scss/app.scss'

import './plugins/axios'

import i18n from "./i18n"

import VBtnElement from '@/components/v-btn/VBtn.vue'
import VImgElement from '@/components/v-img/VImg.vue'

const app = createApp(App)
app.component('VBtn', VBtnElement)
app.component('VImg', VImgElement)

app.use(router)
app.use(store)
app.use(Notifications)
app.use(i18n)
app.mixin(vueMixin)
app.mixin(axiosMixin)
app.mount('#app')

app.directive('click-outside', {
  beforeMount(el, binding, vnode) {
    vnode
    el.clickOutsideEvent = function(event) {
      if (binding.value) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event, el);
        }
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
})