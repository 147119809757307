import axios from 'axios'
import ApiBase from '@/api/base'

export default {
  methods: {
    http (method, url, params, header) {
      method = method.toLowerCase()
      const config = {
        method: method,
        url
      }

      if (method === 'get') {
        config.params = params
        config.headers = Object.assign(header || {})
      } else if (method === 'post' || method === 'put' || method === 'delete') {
        const form = new FormData
        for(let field in params) {
          if (typeof params[field] === 'object') {
            form.append(field, JSON.stringify(params[field]))
          } else {
            form.append(field, params[field])
          }
        }
        config.data = form
        config.headers = Object.assign(header || {}, {
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      }

      return new Promise((resolve, reject) => {
        return axios(config)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (error.response) {
              switch (error.response.status) {
                case 400:
                  reject(error.response.data)
                  break
                case 401:
                  reject(error.response.data)
                  ApiBase.unauthoriseUser()
                  break
                case 404:
                  reject(error.response.data)
                  break
                case 500:
                  reject(error)
                  break
                default:
                  console.error('error', error.response)
                  reject(error)
                  break
              }
            } else {
              console.error(error)
              reject(error)
            }
          })
      })
    }
  }
}
