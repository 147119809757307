export default {
  name: 'RoadmapSection',
  data: () => ({
    mapPoints: [
      {
        code: 'Q1 - 2022',
        name: 'roadMap_item_1_content',
        image: 'building1.png'
      },
      {
        code: 'Q3 - 2022',
        name: 'roadMap_item_2_content',
        image: 'building2.png'
      },
      {
        code: 'Q4 - 2022',
        name: 'roadMap_item_3_content',
        image: 'building3.png'
      },
      {
        code: 'Q1 - 2023',
        name: 'roadMap_item_4_content',
        image: 'building4.png'
      },
      {
        code: 'Q2 - 2023',
        name: 'roadMap_item_5_content',
        image: 'building5.png'
      },
      {
        code: 'Q3 - 2023',
        name: 'roadMap_item_6_content',
        image: 'building6.png'
      },
      {
        code: 'Q1 - 2024',
        name: 'roadMap_item_7_content',
        image: 'building6.png'
      }
    ]
  })
}