<template>
  <button
    :class="[
      'v-btn',
      disabled ? 'disabled' : ''
    ]"
    :style="{
      width: block ? '100%' : width + 'px',
      height: height + 'px',
      fontSize: size + 'px',
      textTransform: uppercase ? 'uppercase' : ''
    }"
  >
    <slot></slot>
  </button>
</template>

<script src="./v-btn.js"></script>

<style lang="scss" scoped>
.v-btn {
  background: transparent;
  border: none;
  color: $white;
  line-height: 14px;
  font-weight: bold;
  cursor: pointer;
}

.v-btn.filled {
  font-weight: 700;
  background: rgba($color: $white, $alpha: 0.1);
  border-radius: 5px;
  padding: 6px 15px;
}

.v-btn.bordered {
  border-radius: 8px;
  border: 1px solid rgba($color: $white, $alpha: 0.1);
  transition: 0.25s;
  &:hover {
    background: rgba($color: $white, $alpha: 0.3);
    border-color: transparent;
  }
}

.v-btn.rounded {
  border-radius: 5px;
}

.v-btn.disabled {
  background-color: #2828284D !important;
  cursor: initial;
}
</style>
