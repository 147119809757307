<template>
  <div id="explanation" class="explanation sections-padding-y menu-anchor">
    <div class="container">
      <div class="content-type-3">
        <h3 class="title-section text-body-2 white--text font-weight-bold">
          {{ $t('explanation_title') }}
        </h3>
        <div class="list-wrapper scroll">
          <div class="card-list d-flex justify-space-between">
            <div class="card px-5 white b-radius-30"
              v-for="card in cards"
              :key="card.name"
            >
              <div class="image-wrapper light-blue">
                <VImg
                  :src="card.icon"
                  width="42"
                  height="42"
                />
              </div>
              <h5 class="font-humans light-blue--text text-body-5 font-weight-bold">
                {{ $t(card.title) }}
              </h5>
              <p class="text-body-6 black-text">
                {{ $t(card.content) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./explanation-section"></script>

<style lang="scss" scoped>
.scroll {
  scrollbar-color: rgba($color: $white, $alpha: 0.8) transparent;
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  height: 10px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba($color: $white, $alpha: 0.8);
  border-radius: 15px;
}
.card {
  width: 311px;
  height: 311px;
  padding-top: 30px;
}
.image-wrapper {
  position: relative;
  width: 72px;
  height: 72px;
  border-radius: 15px;
  .v-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.text-body-5 {
  line-height: 22px;
  margin-top: 35px;
  margin-bottom: 30px;
}
.text-body-6 {
  line-height: 19px;
}
@media only screen and (max-width: $md) {
  .list-wrapper {
    margin: 0 -24px;
    padding: 0px 24px 50px;
    overflow-x: auto;
  }
  .card-list {
    width: 973px;
  }
  .card {
    height: 266px;
  }
  .text-body-5 {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: $xs) {
  .scroll {
    scrollbar-width: none;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
   .list-wrapper {
    margin: 0 -20px;
    padding: 0px 20px 50px
  }
}
</style>