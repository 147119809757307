<template>
  <div id="roadMap" class="road-map sections-padding-y menu-anchor">
    <div class="container">
      <div class="content-type-2 white text-center">
        <h3 class="title-section text-body-2 light-blue--text font-weight-bold">
          {{ $t('roadMap_title') }}
        </h3>
        <div v-if="!isMobile" class="image-wrapper relative">
          <VImg
            src="roadmapMainImage.png"
            width="100%"
            :height="isTablet ? '377' : '313'"
          />
          <div
            class="map-point absolute"
            v-for="mapPoint in mapPoints"
            :key="mapPoint.name"
          >
            <h4 class="text-body-4 light-blue--text font-weight-bold font-humans">
              {{ mapPoint.code }}
            </h4>
            <p class="text-body-6 black-text">
              {{ $t(mapPoint.name) }}
            </p>
          </div>
        </div>
        <div v-else class="d-flex flex-column align-center gap-15">
          <div
            class="map-point-mobile d-flex align-center"
            v-for="mapPoint in mapPoints"
            :key="mapPoint.name"
          >
            <div class="relative">
              <VImg
                :src="mapPoint.image"
                width="110"
                height="110"
              />
              <div class="line light-blue b-radius-30 absolute" />
            </div>
            <div class="map-point-text">
              <h4 class="text-body-4 light-blue--text font-weight-bold font-humans">
                {{ mapPoint.code }}
              </h4>
              <p class="text-body-6 black-text">
                {{ $t(mapPoint.name) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./roadmap-section"></script>

<style lang="scss" scoped>
.road-map {
  margin-bottom: 150px;
}
.image-wrapper {
  padding: 88px 0px;
}
.text-body-6 {
  margin-top: 6px;
  line-height: 19px;
}
.line {
  width: 8px;
  height: 40px;
  left: 50%;
  transform: translateX(-50%);
  top: 120px;
}
.map-point {
  width: 200px;
  &:nth-of-type(2) {
    left: 24px;
    top: 328px;
  }
  &:nth-of-type(3) {
    left: 139px;
    top: 47px;
  }
  &:nth-of-type(4) {
    left: 271px;
    top: 421px;
  }
  &:nth-of-type(5) {
    left: 422px;
    top: 0px;
  }
  &:nth-of-type(6) {
    left: 496px;
    top: 485px;
  }
  &:nth-of-type(7) {
    left: 647px;
    top: 132px;
  }
  &:last-of-type {
    left: 811px;
    top: 485px;
  }
}
@media only screen and (max-width: $md) {
  .content-type-2 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .image-wrapper {
    padding: 76px 0px;
    max-width: 856px;
    margin: 0 auto;
  }
  .map-point {
    width: 185px;
    &:nth-of-type(2) {
      left: 9px;
      top: 275px;
    }
    &:nth-of-type(3) {
      left: 111px;
      top: 6px;
    }
    &:nth-of-type(4) {
      left: 211px;
      top: 352px;
    }
    &:nth-of-type(5) {
      left: 341px;
      top: -14px;
    }
    &:nth-of-type(6) {
      left: 401px;
      top: 403px;
    }
    &:nth-of-type(7) {
      left: 521px;
      top: 90px;
    }
    &:last-of-type {
      left: 656px;
      top: 400px;
    }
  }
}
@media only screen and (max-width: $sm) {
  .map-point-mobile {
    margin-left: -20px;
    &:last-of-type {
      .line {
        display: none;
      }
    }
  }
  .map-point-text {
    width: 190px;
    text-align: start;
  }
  .text-body-6 {
    margin-top: 10px;
  }
}
</style>
