import { createWebHistory, createRouter } from 'vue-router'

import Home from './routes/home'
import PrivacyPolicy from './routes/privacy-policy.js'
import TermsAndConditions from './routes/terms-and-conditions.js'

const router = createRouter({
	history: createWebHistory(),
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			component: () => import('@/components/main-container/MainContainer.vue'),
			children: [
				Home,
        PrivacyPolicy,
				TermsAndConditions,
			]
		}
	]
})

router.beforeEach((to) => {
	if (to.hash) {
		const el = document.getElementById(to.hash.replace('#', ''))
		if (el) {
			window.scrollTo({
				top: el.offsetTop - 120,
				behavior: 'smooth'
			})
		}
	} else {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}
})

export default router
