export default {
  name: 'VImg',
  props: {
    src: {
      type: String
    },
    width: {
      type: String
    },
    height: {
      type: String
    }
  }
}
