export default {
  name: 'UnitedMetasSection',
  data: () => ({
    cards: [
      {
        name: 'unitedmetas_card_defi',
        icon: 'uMCardIcon1.svg'
      },
      {
        name: 'unitedmetas_card_nfts',
        icon: 'uMCardIcon2.svg'
      },
      {
        name: 'unitedmetas_card_swap',
        icon: 'uMCardIcon3.svg'
      },
      {
        name: 'unitedmetas_card_marketplace',
        icon: 'uMCardIcon4.svg'
      },
      {
        name:  'unitedmetas_card_priorityAccess',
        icon: 'uMCardIcon5.svg'
      }
    ],
    partners: [
      { iconName: 'binance.svg' },
      { iconName: 'pamcake_swap.svg' },
      { iconName: 'coinmarketcap.svg' },
      { iconName: 'coingecko.svg' },
      { iconName: 'moonpay.svg' },
      { iconName: 'wyre.svg' },
      { iconName: 'xanpool.svg' },
      { iconName: 'mercuryo.svg' },
      { iconName: 'indacdin.svg' },
      { iconName: 'coinify.svg' },
      { iconName: 'BTCDirect.svg' },
      { iconName: 'UTORG.svg' },
    ]
  })
}
