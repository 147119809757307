<template>
  <div id="unitedMetas" class="united-metas sections-padding-y menu-anchor">
    <div class="container">
      <div class="content-type-3">
        <h3 class="title-section text-body-2 white--text font-weight-bold">
          {{ $t('unitedmetas_title') }}
        </h3>
        <h4 class="sub-title text-body-3 white--text font-weight-semi-bold mb-10">
          {{ $t('unitedmetas_subTitle') }}
        </h4>
        <p class="text-content text-body-5 white--text">
          {{ $t('unitedmetas_content') }}
        </p>
        <h3 class="title-section text-body-2 white--text font-weight-bold">
          {{ $t('our_partners') }}
        </h3>
        <div
          :class="[
              'partners d-flex flex-wrap',
              isMobile ? 'justify-center' : 'justify-space-between'
            ]"
          >
          <img
            v-for="item in partners"
            :key="item.iconName"
            :src="require(`../../../assets/images/${item.iconName}`)" width="220"
            :class="[
              'mx-1',
              isMobile ? 'mb-8' : 'mb-20'
            ]"
          >
        </div>
      </div>
      <div class="content-type-3 market-list">
        <div
          :class="[
            'card-list d-flex',
            isTablet ? 'justify-space-between' : 'justify-center flex-wrap'
          ]"
        >
          <div
            :class="[
              'card text-center',
              isMobile ? 'px-2 b-radius-20' : 'px-4 b-radius-30'
            ]"
            v-for="card in cards"
            :key="card.name"
          >
            <VImg
              :src="card.icon"
              :width="isMobile ? '40' : '72'"
              :height="isMobile ? '40' : '72'"
            />
            <span class="text-body-5 white--text font-weight-bold">
              {{ $t(card.name) }}
            </span>
          </div>
        </div>
      </div>
      <div
        :class="[
          'visit-us-here content-type-2 d-flex',
          isTablet ? 'justify-space-between' : 'flex-column'
        ]"
      >
        <div
          :class="[
            'visit-us-text-block',
            isTablet ? '' : 'mb-10'
          ]"
        >
          <h3 class="title-section text-body-2 white--text font-weight-bold">
            {{ $t('visit_us_here') }}
          </h3>
          <p class="text-body-5 white--text mb-8">
            {{ $t('visit_us_p1') }}
          </p>
          <p class="text-body-5 white--text">
            {{ $t('visit_us_p2') }}
          </p>
        </div>
        <div
          :class="[
            'relative visit-img-block',
            isMobile ? 'align-self-center' : isTablet ? '' : 'align-self-end'
          ]"
        >
          <VImg
            :class="[
              'visit-img bg-cover',
              isMobile ? 'mb-6' : 'mb-10'
            ]"
            src="visit_1.jpg"
            :height="isMobile ? '250' : '324'"
            :width="isMobile ? '100%' : '399'"
          />
          <VImg
            :class="[
              'visit-img visit-img-2 bg-cover',
              isMobile ? 'mb-6' : ''
            ]"
            src="visit_2.jpg"
            :width="isMobile ? '100%' : '368'"
            :height="isMobile ? '250' : '299'"
          />
          <VImg
            :class="[
              'visit-img visit-img-3 bg-cover',
            ]"
            src="visit_3.jpg"
            :width="isMobile ? '100%' : '330'"
            :height="isMobile ? '250' : '268'"
          />
        </div>
      </div>
      <div class="content-type-3">
        <h3 class="title-section text-body-2 white--text font-weight-bold">
          {{ $t('about_dmcc') }}
        </h3>
      </div>
      <a href="https://www.dmcc.ae/"
        class="about-section content-type-2"
        target="_blank"
      >
        <div class="about-image" />
        <div class="about-content">
          <h2 class="about-title">
            DMCC
          </h2>
          <p class="about-text text-body-3 white--text mt-5 font-weight-bold">
            {{ $t('about_dmcc_text') }}
          </p>
        </div>
      </a>
    </div>
  </div>
</template>

<script src="./united-metas-section"></script>

<style lang="scss" scoped>
.united-metas {
  margin-top: 50px;
}
.text-body-5 {
  line-height: 21px;
}
.visit-us-text-block {
  max-width: 560px;
}
.visit-img-block {
  width: 399px;
}
.text-content {
  margin-bottom: 100px;
}
.card {
  width: 186px;
  height: 183px;
  border: 1px solid $white;
  .v-image {
    margin: 30px auto 30px;
  }
}
.visit-us-here {
  padding: 100px 80px;
  border: 2px solid $white;
  margin-bottom: 100px;
  height: 834px;
}
.visit-img {
  border-radius: 35px;
}
.visit-img-2 {
  position: absolute;
  bottom: 14%;
  right: 330px;
}
.visit-img-3 {
  position: absolute;
  left: 20px;
}
.market-list {
  margin-bottom: 100px;
}
.about-section {
  height: 209px;
  border-radius: 35px;
  display: block;

  background-size: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;

  .about-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-image: url('../../../assets/images/about_dmcc_bg.png');
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  &:hover {
    .about-image {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }
  .about-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 50px 84px;
  }
}
.about-title {
  font-size: 58px;
  font-weight: 900;
  line-height: 1;
  color: #fff;
}
@media only screen and (max-width: $md) {
  .visit-us-here {
    height: 1100px;
  }
  .card-list {
    max-width: 612px;
    margin: 0 auto;
    column-gap: 10px;
    row-gap: 20px;
  }
}
@media only screen and (max-width: $sm) {
  .card {
    width: 100px;
    height: 100px;
    .v-image {
      margin: 12px auto 6px;
    }
  }
  .text-body-5 {
    font-size: 12px;
    line-height: 14px;
  }
  .visit-img-2 {
    position: initial;
  }
  .visit-img-3 {
    position: initial;
  }
  .visit-us-here {
    height: auto;
    padding: 40px;
  }
  .visit-img-block {
    width: 100%;
  }
  .about-section {
    height: 220px;

    .about-image {
      background-size: cover;
    }

    .about-content {
      padding: 28px 40px;
    }
  }
}
</style>
