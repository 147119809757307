<template>
<div class="page home-page highlight-transparent">
  <PreviewSection />
  <UnitedMetasSection />
  <MissionSection />
  <ExplanationSection />
  <PhasesSection />
  <RoadmapSection />
</div>
</template>

<script>
import PreviewSection from '@/components/home/preview-section/PreviewSection.vue'
import UnitedMetasSection from '@/components/home/united-metas-section/UnitedMetasSection.vue'
import MissionSection from '@/components/home/mission-section/MissionSection.vue'
import ExplanationSection from '@/components/home/explanation-section/ExplanationSection.vue'
import PhasesSection from '@/components/home/phases-section/PhasesSection.vue'
import RoadmapSection from '@/components/home/roadmap-section/RoadmapSection'

export default {
  name: 'Home',
  components: {
    PreviewSection,
    UnitedMetasSection,
    MissionSection,
    ExplanationSection,
    PhasesSection,
    RoadmapSection
  }
}
</script>