<template>
  <div
    :class="[
      'privacy-police',
      isMobile ? 'mt-15 pt-10 pb-10' : 'mt-25 pt-13 pb-15'
    ]"
  >
    <div class="content-section px-6">
      <div class="head pb-6">
        <span class="d-block main-text">
          This Application collects some Personal Data from its Users.
        </span>
        <br>
        <span class="d-block main-text">
          This document can be printed for reference by using the print command in the settings of any browser.
        </span>
      </div>
      <div class="policy-summary border-bottom">
        <h3 class="chapter-head borders text-center py-6">
          Policy summary
        </h3>
        <h4 class="chapter-head py-6">
          Personal Data processed for the following purposes and using the following services:
        </h4>
        <div
          :class="[
            'd-flex justify-space-between',
            isMobile ? 'flex-column' : ''
          ]"
        >
          <div class="column">
            <div class="d-flex gap-4 mb-6">
              <VImg
                src="policyIcon1.png"
                width="24"
                height="24"
              />
              <div class="summary-text-block">
                <h6 class="small-head mb-2">
                  Analytics
                </h6>
                <p class="main-text font-weight-bold">
                  Google Analytics and Facebook Ads conversion tracking (Facebook pixel)
                </p>
                <p class="main-text">
                  Personal Data: Cookies; Usage Data
                </p>
              </div>
            </div>
            <div class="d-flex gap-4 mb-6">
              <VImg
                src="policyIcon2.png"
                width="24"
                height="24"
              />
              <div class="summary-text-block">
                <h6 class="small-head mb-2">
                  Displaying content from external platforms
                </h6>
                <p class="main-text font-weight-bold">
                  Google Fonts
                </p>
                <p class="main-text">
                  Personal Data: Usage Data; various types of Data as specified in the privacy policy of the service
                </p>
              </div>
            </div>
            <div class="d-flex gap-4 mb-6">
              <VImg
                src="policyIcon2.png"
                width="24"
                height="24"
              />
              <div class="summary-text-block">
                <h6 class="small-head mb-2">
                  Traffic optimization and distribution
                </h6>
                <p class="main-text font-weight-bold">
                  Cloudflare
                </p>
                <p class="main-text">
                  Personal Data: Cookies; various types of Data as specified in the privacy policy of the service
                </p>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="d-flex gap-4 mb-6">
              <VImg
                src="policyIcon3.png"
                width="24"
                height="24"
              />
              <div class="summary-text-block">
                <h6 class="small-head mb-2">
                  Contacting the User
                </h6>
                <p class="main-text font-weight-bold">
                  Contact form
                </p>
                <p class="main-text mb-2">
                  Personal Data: email address; phone number
                </p>
                <p class="main-text font-weight-bold">
                  Mailing list or newsletter
                </p>
                <p class="main-text">
                  Personal Data: email address; first name; last name; phone number
                </p>
              </div>
            </div>
            <div class="d-flex gap-4 mb-6">
              <VImg
                src="policyIcon2.png"
                width="24"
                height="24"
              />
              <div class="summary-text-block">
                <h6 class="small-head mb-2">
                  Hosting and backend infrastructure
                </h6>
                <p class="main-text font-weight-bold">
                  GitHub Pages and Google Cloud Storage
                </p>
                <p class="main-text mb-2">
                  Personal Data: various types of Data as specified in the privacy policy of the service
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="further-information pb-6 border-bottom">
        <h4 class="chapter-head py-6">
          Further information about the processing of Personal Data
        </h4>
        <div class="d-flex gap-4">
          <VImg
            src="policyIcon2.png"
            width="24"
            height="24"
          />
          <div class="text-block">
            <h6 class="small-head mb-2">
              Blockchain
            </h6>
            <p class="main-text">
              Many blockchains, including the Ethereum Blockchain, may provide public access to transactions that individuals broadcast via United Metas due to their inherent transparency. This could include, but is not limited to, your public sending address, the public address of the receiver, the amount sent or received, and any other data that users have chosen to include in a given transaction. Information that is stored on a blockchain may be public, immutable, and could be impossible to be deleted. User's identity may be revealed within transactions and addresses and information can potentially be correlated now or in the future by any party who chooses to do so, including law enforcement.
            </p>
          </div>
        </div>
        <div class="d-flex gap-4 mt-6">
          <VImg
            src="policyIcon2.png"
            width="24"
            height="24"
          />
          <div class="text-block">
            <h6 class="small-head mb-2">
              Collecting Information
            </h6>
            <p class="main-text">
              United Metas was created to collect as little personal information as possible while efficiently providing it's use. United Metas does not sell any information or data to any third party. Private keys, passwords and All information necessary to use United Metas are owned, controlled, managed, and possessed by Users, including Private Keys and Passwords (unless the User uses another service or product which is outside of our control).
            </p>
          </div>
        </div>
        <div class="d-flex gap-4 mt-6">
          <VImg
            src="policyIcon2.png"
            width="24"
            height="24"
          />
          <div class="text-block">
            <h6 class="small-head mb-2">
              United Metas Interface
            </h6>
            <p class="main-text">
              The United Metas interface (including key-pair generator and unlocking functionality) never records, stores nor transmits any of your private keys or encryption passwords. If you use a third-party service, such as MetaMask, Ledger etc. you are subject to their terms and policies.
            </p>
          </div>
        </div>
      </div>
      <div class="contact-information">
        <h4 class="chapter-head py-6">
          Company details
        </h4>
        <div class="contact-info d-flex gap-4 mb-6">
          <VImg
            src="policyIcon4.png"
            width="24"
            height="24"
          />
          <div class="text-block">
            <span class="small-head">
              Owner and Data Controller
            </span>
            <span class="d-block main-text">
              United Metas DMCC
            </span>
            <br>
            <span class="d-block main-text">
              Unit No: 801
            </span>
            <span class="d-block main-text">
              Jumeirah Lakes Towers
            </span>
            <span class="d-block main-text">
              Dubai
            </span>
            <span class="d-block main-text mb-3">
              UAE
            </span>
          </div>
        </div>
      </div>
      <div class="full-summary">
      <h3 class="chapter-head mt-6">
        Types of Data collected
      </h3>
      <p class="main-text mt-1">
        Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Cookies; Usage Data; phone number; email address; first name; last name.
      </p>
      <p class="main-text mt-2">
        Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.
      </p>
      <p class="main-text">
        Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application.
      </p>
      <p class="main-text">
        Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services. In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.
      </p>
      <p class="main-text">
        Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.
      </p>
      <p class="main-text">
        Any use of Cookies – or of other tracking tools – by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy, if available.
      </p>
      <p class="main-text mt-2 pb-6 border-bottom">
        Users are responsible for any third-party Personal Data obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.
      </p>
      <h3 class="chapter-head py-6">
        Mode and place of processing the Data
      </h3>
      <p class="main-text font-weight-bold">
        Methods of processing
      </p>
      <p class="main-text">
        The Owner takes appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.
      </p>
      <p class="main-text">
        The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of this Application (administration, sales, marketing, legal, system administration) or external parties (such as third-party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Owner at any time.
      </p>
      <br>
      <p class="main-text font-weight-bold">
        Legal basis of processing
      </p>
      <p class="main-text">
        The Owner may process Personal Data relating to Users if one of the following applies:
      </p>
      <ul class="main-text ">
        <li class="main-text ml-8">
          Users have given their consent for one or more specific purposes. Note: Under some legislations the Owner may be allowed to process Personal Data until the User objects to such processing (“opt-out”), without having to rely on consent or any other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data is subject to European data protection law;
        </li>
        <li class="main-text ml-8">
          provision of Data is necessary for the performance of an agreement with the User and/or for any pre-contractual obligations thereof;
        </li>
        <li class="main-text ml-8">
          processing is necessary for compliance with a legal obligation to which the Owner is subject;
        </li>
        <li class="main-text ml-8">
          processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Owner;
        </li>
        <li class="main-text ml-8">
          processing is necessary for the purposes of the legitimate interests pursued by the Owner or by a third party.
        </li>
      </ul>
      <p class="main-text mt-2">
        In any case, the Owner will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
      </p>
      <br>
      <p class="main-text font-weight-bold">
        Place
      </p>
      <p class="main-text">
        The Data is processed at the Owner's operating offices and in any other places where the parties involved in the processing are located.
      </p>
      <br>
      <p class="main-text">
        Depending on the User's location, data transfers may involve transferring the User's Data to a country other than their own. To find out more about the place of processing of such transferred Data, Users can check the section containing details about the processing of Personal Data.
      </p>
      <p class="main-text mt-2">
        Users are also entitled to learn about the legal basis of Data transfers to a country outside the European Union or to any international organization governed by public international law or set up by two or more countries, such as the UN, and about the security measures taken by the Owner to safeguard their Data.
      </p>
      <br>
      <p class="main-text">
        If any such transfer takes place, Users can find out more by checking the relevant sections of this document or inquire with the Owner using the information provided in the contact section.
      </p>
      <p class="main-text font-weight-bold">
        Retention time
      </p>
      <p class="main-text">
        Personal Data shall be processed and stored for as long as required by the purpose they have been collected for.
      </p>
      <p class="main-text mt-2">
        Therefore:
      </p>
      <ul>
        <li class="main-text ml-8">
          Personal Data collected for purposes related to the performance of a contract between the Owner and the User shall be retained until such contract has been fully performed.
        </li>
        <li class="main-text ml-8">
          Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained as long as needed to fulfill such purposes. Users may find specific information regarding the legitimate interests pursued by the Owner within the relevant sections of this document or by contacting the Owner.
        </li>
      </ul>
      <p class="main-text mt-2">
        The Owner may be allowed to retain Personal Data for a longer period whenever the User has given consent to such processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to retain Personal Data for a longer period whenever required to do so for the performance of a legal obligation or upon order of an authority.
      </p>
      <br>
      <p class="main-text pb-6 border-bottom">
        Once the retention period expires, Personal Data shall be deleted. Therefore, the right of access, the right to erasure, the right to rectification and the right to data portability cannot be enforced after expiration of the retention period.
      </p>
      <h3 class="chapter-head mt-6">
        The purposes of processing
      </h3>
      <p class="main-text mt-1">
        The Data concerning the User is collected to allow the Owner to provide its Service, comply with its legal obligations, respond to enforcement requests, protect its rights and interests (or those of its Users or third parties), detect any malicious or fraudulent activity, as well as the following: Analytics, Contacting the User, Displaying content from external platforms, Traffic optimization and distribution and Hosting and backend infrastructure.
      </p>
      <p class="main-text mt-2 pb-6 border-bottom">
        For specific information about the Personal Data used for each purpose, the User may refer to the section “Detailed information on the processing of Personal Data”.
      </p>
      <h3 class="chapter-head mt-6">
        Detailed information on the processing of Personal Data
      </h3>
      <p class="main-text mt-1">
        Personal Data is collected for the following purposes and using the following services:
      </p>
      <div class="text-block d-flex gap-4 mt-6">
          <VImg
            src="policyIcon2.png"
            width="24"
            height="24"
          />
          <div>
            <h6 class="small-head mb-2">
              Analytics
            </h6>
            <p class="main-text">
              The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior
            </p>
            <p class="main-text font-weight-bold mt-2">
              Google Analytics (Google Ireland Limited)
            </p>
            <p class="main-text mt-2">
              Google Analytics is a web analysis service provided by Google Ireland Limited (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.
            </p>
            <p class="main-text">
              Google may use the Data collected to contextualize and personalize the ads of its own advertising network.
            </p>
            <p class="main-text mt-2">
              Personal Data processed: Cookies; Usage Data.
            </p>
            <p class="main-text mt-2">
              Place of processing: Ireland – Privacy Policy – Opt Out.
            </p>
            <p class="main-text font-weight-bold mt-2">
              Facebook Ads conversion tracking (Facebook pixel) (Facebook Ireland Ltd)
            </p>
            <p class="main-text mt-2">
              Facebook Ads conversion tracking (Facebook pixel) is an analytics service provided by Facebook Ireland Ltd that connects data from the Facebook advertising network with actions performed on this Application. The Facebook pixel tracks conversions that can be attributed to ads on Facebook, Instagram and Audience Network.
            </p>
            <p class="main-text mt-2">
              Personal Data processed: Cookies; Usage Data.
            </p>
            <p class="main-text mt-2">
              Place of processing: Ireland – Privacy Policy.
            </p>
          </div>
      </div>
      <div class="text-block d-flex gap-4 mt-6">
          <VImg
            src="policyIcon2.png"
            width="24"
            height="24"
          />
          <div class="text-block">
            <h6 class="small-head mb-2">
              Displaying content from external platforms
            </h6>
            <p class="main-text mt-2">
              This type of service allows you to view content hosted on external platforms directly from the pages of this Application and interact with them.
            </p>
            <p class="main-text">
              This type of service might still collect web traffic data for the pages where the service is installed, even when Users do not use it.
            </p>
            <p class="main-text font-weight-bold mt-2">
              Google Fonts (Google Ireland Limited)
            </p>
            <p class="main-text mt-2">
              Google Fonts is a typeface visualization service provided by Google Ireland Limited that allows this Application to incorporate content of this kind on its pages.
            </p>
            <p class="main-text mt-2">
              Personal Data processed: Usage Data; various types of Data as specified in the privacy policy of the service.
            </p>
            <p class="main-text mt-2">
              Place of processing: Ireland – Privacy Policy.
            </p>
          </div>
      </div>
      <div class="text-block d-flex gap-4 mt-6">
          <VImg
            src="policyIcon2.png"
            width="24"
            height="24"
          />
          <div class="text-block">
            <h6 class="small-head mb-2">
              Traffic optimization and distribution
            </h6>
            <p class="main-text mt-2">
              This type of service allows this Application to distribute their content using servers located across different countries and to optimize their performance.
            </p>
            <p class="main-text">
              Which Personal Data are processed depends on the characteristics and the way these services are implemented. Their function is to filter communications between this Application and the User's browser.
            </p>
            <p class="main-text">
              Considering the widespread distribution of this system, it is difficult to determine the locations to which the contents that may contain Personal Information of the User are transferred.
            </p>
            <p class="main-text font-weight-bold mt-2">
              Cloudflare (Cloudflare Inc.)
            </p>
            <p class="main-text mt-2">
              Cloudflare is a traffic optimization and distribution service provided by Cloudflare Inc.
            </p>
            <p class="main-text">
              The way Cloudflare is integrated means that it filters all the traffic through this Application, i.e., communication between this Application and the User's browser, while also allowing analytical data from this Application to be collected.
            </p>
            <p class="main-text mt-2">
              Personal Data processed: Cookies; various types of Data as specified in the privacy policy of the service.
            </p>
            <p class="main-text mt-2">
              Place of processing: United States – Privacy Policy.
            </p>
          </div>
      </div>
      <div class="text-block d-flex gap-4 mt-6">
          <VImg
            src="policyIcon2.png"
            width="24"
            height="24"
          />
          <div class="text-block">
            <h6 class="small-head mb-2">
              Contacting the User
            </h6>
            <p class="main-text font-weight-bold mt-2">
              Contact form (this Application)
            </p>
            <p class="main-text mt-2">
              By filling in the contact form with their Data, the User authorizes this Application to use these details to reply to requests for information, quotes or any other kind of request as indicated by the form’s header.
            </p>
            <p class="main-text mt-2">
              Personal Data processed: email address; phone number.
            </p>
            <p class="main-text font-weight-bold mt-2">
              Mailing list or newsletter (this Application)
            </p>
            <p class="main-text mt-2">
              By registering on the mailing list or for the newsletter, the User’s email address will be added to the contact list of those who may receive email messages containing information of commercial or promotional nature concerning this Application. Your email address might also be added to this list as a result of signing up to this Application or after making a purchase.
            </p>
            <p class="main-text mt-2">
              Personal Data processed: email address; first name; last name; phone number.
            </p>
          </div>
      </div>
      <div class="text-block d-flex gap-4 mt-6 pb-6 border-bottom">
          <VImg
            src="policyIcon2.png"
            width="24"
            height="24"
          />
          <div class="text-block">
            <h6 class="small-head mb-2">
              Hosting and backend infrastructure
            </h6>
            <p class="main-text mt-2">
              This type of service has the purpose of hosting Data and files that enable this Application to run and be distributed as well as to provide a ready-made infrastructure to run specific features or parts of this Application.
            </p>
            <p class="main-text mt-2">
              Some services among those listed below, if any, may work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data are stored.
            </p>
            <p class="main-text font-weight-bold mt-2">
              GitHub Pages (GitHub Inc.)
            </p>
            <p class="main-text mt-2">
              GitHub Pages is a hosting service provided by GitHub, Inc.
            </p>
            <p class="main-text mt-2">
              Personal Data processed: various types of Data as specified in the privacy policy of the service.
            </p>
            <p class="main-text mt-2">
              Place of processing: United States – Privacy Policy.
            </p>
            <p class="main-text font-weight-bold mt-2">
              Google Cloud Storage (Google Ireland Limited)
            </p>
            <p class="main-text mt-2">
              Google Cloud Storage is a hosting service provided by Google Ireland Limited.
            </p>
            <p class="main-text mt-2">
              Personal Data processed: various types of Data as specified in the privacy policy of the service.
            </p>
            <p class="main-text mt-2">
              Place of processing: Ireland – Privacy Policy.
            </p>
          </div>
      </div>
      <h3 class="chapter-head mt-6">
        Further information about the processing of Personal Data
      </h3>
      <div class="text-block d-flex gap-4 mt-6">
          <VImg
            src="policyIcon2.png"
            width="24"
            height="24"
          />
          <div>
            <h6 class="small-head mb-2">
              Blockchain
            </h6>
            <p class="main-text mt-2">
              Many blockchains, including the Ethereum Blockchain, may provide public access to transactions that individuals broadcast via United Metas due to their inherent transparency. This could include, but is not limited to, your public sending address, the public address of the receiver, the amount sent or received, and any other data that users have chosen to include in a given transaction. Information that is stored on a blockchain may be public, immutable, and could be impossible to be deleted. User's identity may be revealed within transactions and addresses and information can potentially be correlated now or in the future by any party who chooses to do so, including law enforcement.
            </p>
          </div>
      </div>
      <div class="text-block d-flex gap-4 mt-6">
          <VImg
            src="policyIcon2.png"
            width="24"
            height="24"
          />
          <div>
            <h6 class="small-head mb-2">
              United Metas Interface
            </h6>
            <p class="main-text mt-2">
              The United Metas interface (including key-pair generator and unlocking functionality) never records, stores nor transmits any of your private keys or encryption passwords. If you use a third-party service, such as MetaMask, Ledger etc. you are subject to their terms and policies.
            </p>
          </div>
      </div>
      <div class="text-block d-flex gap-4 mt-6 pb-6 border-bottom">
          <VImg
            src="policyIcon2.png"
            width="24"
            height="24"
          />
          <div>
            <h6 class="small-head mb-2">
              Collecting Information
            </h6>
            <p class="main-text mt-2">
              United Metas was created to collect as little personal information as possible while efficiently providing it's use. United Metas does not sell any information or data to any third party. Private keys, passwords and All information necessary to use United Metas are owned, controlled, managed, and possessed by Users, including Private Keys and Passwords (unless the User uses another service or product which is outside of our control).
            </p>
          </div>
      </div>
      <h3 class="chapter-head mt-6">
        The rights of Users
      </h3>
      <p class="main-text mt-2">
        Users may exercise certain rights regarding their Data processed by the Owner.
      </p>
      <p class="main-text mt-2">
        In particular, Users have the right to do the following:
      </p>
      <ul>
        <li class="main-text ml-8">
          <span class="main-text font-weight-bold">
            Withdraw their consent at any time.
          </span>
          Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data.
        </li>
        <li class="main-text ml-8">
          <span class="main-text font-weight-bold">
            Object to processing of their Data.
          </span>
          Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below.
        </li>
        <li class="main-text ml-8">
          <span class="main-text font-weight-bold">
            Access their Data.
          </span>
          Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing.
        </li>
        <li class="main-text ml-8">
          <span class="main-text font-weight-bold">
            Verify and seek rectification.
          </span>
          Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected.
        </li>
        <li class="main-text ml-8">
          <span class="main-text font-weight-bold">
            Restrict the processing of their Data.
          </span>
          Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it.
        </li>
        <li class="main-text ml-8">
          <span class="main-text font-weight-bold">
            Have their Personal Data deleted or otherwise removed.
          </span>
          Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner.
        </li>
        <li class="main-text ml-8">
          <span class="main-text font-weight-bold">
            Receive their Data and have it transferred to another controller.
          </span>
          Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof.
        </li>
        <li class="main-text ml-8">
          <span class="main-text font-weight-bold">
            Lodge a complaint.
          </span>
          Users have the right to bring a claim before their competent data protection authority.
        </li>
      </ul>
      <p class="main-text font-weight-bold mt-6">
        Details about the right to object to processing
      </p>
      <p class="main-text">
        Where Personal Data is processed for a public interest, in the exercise of an official authority vested in the Owner or for the purposes of the legitimate interests pursued by the Owner, Users may object to such processing by providing a ground related to their particular situation to justify the objection.
      </p>
      <p class="main-text mt-2">
        Users must know that, however, should their Personal Data be processed for direct marketing purposes, they can object to that processing at any time without providing any justification. To learn, whether the Owner is processing Personal Data for direct marketing purposes, Users may refer to the relevant sections of this document.
      </p>
      <p class="main-text font-weight-bold mt-6">
        How to exercise these rights
      </p>
      <p class="main-text pb-6 border-bottom">
        Any requests to exercise User rights can be directed to the Owner through the contact details provided in this document. These requests can be exercised free of charge and will be addressed by the Owner as early as possible and always within one month.
      </p>
      <h3 class="chapter-head mt-6">
        Additional information about Data collection and processing
      </h3>
      <p class="main-text font-weight-bold mt-6">
        Legal action
      </p>
      <p class="main-text">
        The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services.
      </p>
      <p class="main-text">
        The User declares to be aware that the Owner may be required to reveal personal data upon request of public authorities.
      </p>
      <p class="main-text font-weight-bold mt-6">
        Additional information about User's Personal Data
      </p>
      <p class="main-text">
        In addition to the information contained in this privacy policy, this Application may provide the User with additional and contextual information concerning particular Services or the collection and processing of Personal Data upon request.
      </p>
      <p class="main-text font-weight-bold mt-6">
        System logs and maintenance
      </p>
      <p class="main-text">
        For operation and maintenance purposes, this Application and any third-party services may collect files that record interaction with this Application (System logs) use other Personal Data (such as the IP Address) for this purpose.
      </p>
      <p class="main-text font-weight-bold mt-6">
        Information not contained in this policy
      </p>
      <p class="main-text">
        More details concerning the collection or processing of Personal Data may be requested from the Owner at any time. Please see the contact information at the beginning of this document.
      </p>
      <p class="main-text font-weight-bold mt-6">
        How “Do Not Track” requests are handled
      </p>
      <p class="main-text">
        This Application does not support “Do Not Track” requests.
      </p>
      <p class="main-text">
        To determine whether any of the third-party services it uses honor the “Do Not Track” requests, please read their privacy policies.
      </p>
      <p class="main-text font-weight-bold mt-6">
        Changes to this privacy policy
      </p>
      <p class="main-text">
        The Owner reserves the right to make changes to this privacy policy at any time by notifying its Users on this page and possibly within this Application and/or - as far as technically and legally feasible - sending a notice to Users via any contact information available to the Owner. It is strongly recommended to check this page often, referring to the date of the last modification listed at the bottom.
      </p>
      <p class="main-text mt-2 pb-6 border-bottom">
        Should the changes affect processing activities performed on the basis of the User’s consent, the Owner shall collect new consent from the User, where required.
      </p>
      <div class="text-block d-flex gap-4 mt-6 pb-6 border-bottom">
          <VImg
            src="policyIcon2.png"
            width="24"
            height="24"
          />
          <div>
            <h6 class="small-head mb-2">
              Definitions and legal references
            </h6>
            <p class="main-text font-weight-bold mt-6">
              Personal Data (or Data)
            </p>
            <p class="main-text mt-2">
              Any information that directly, indirectly, or in connection with other information — including a personal identification number — allows for the identification or identifiability of a natural person.
            </p>
            <p class="main-text font-weight-bold mt-6">
              Usage Data
            </p>
            <p class="main-text mt-2">
              Information collected automatically through this Application (or third-party services employed in this Application), which can include: the IP addresses or domain names of the computers utilized by the Users who use this Application, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Application) and the details about the path followed within the Application with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.
            </p>
            <p class="main-text font-weight-bold mt-6">
              User
            </p>
            <p class="main-text mt-2">
              The individual using this Application who, unless otherwise specified, coincides with the Data Subject.
            </p>
            <p class="main-text font-weight-bold mt-6">
              Data Subject
            </p>
            <p class="main-text mt-2">
              The natural person to whom the Personal Data refers.
            </p>
            <p class="main-text font-weight-bold mt-6">
              Data Processor (or Data Supervisor)
            </p>
            <p class="main-text mt-2">
              The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.
            </p>
            <p class="main-text font-weight-bold mt-6">
              Data Controller (or Owner)
            </p>
            <p class="main-text mt-2">
              The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Application. The Data Controller, unless otherwise specified, is the Owner of this Application.
            </p>
            <p class="main-text font-weight-bold mt-6">
              This Application
            </p>
            <p class="main-text mt-2">
              The means by which the Personal Data of the User is collected and processed.
            </p>
            <p class="main-text font-weight-bold mt-6">
              Service
            </p>
            <p class="main-text mt-2">
              The service provided by this Application as described in the relative terms (if available) and on this site/application.
            </p>
            <p class="main-text font-weight-bold mt-6">
              European Union (or EU)
            </p>
            <p class="main-text mt-2">
              Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.
            </p>
            <p class="main-text font-weight-bold mt-6">
              Cookie
            </p>
            <p class="main-text mt-2">
              Cookies are Trackers consisting of small sets of data stored in the User's browser.
            </p>
            <p class="main-text font-weight-bold mt-6">
              Tracker
            </p>
            <p class="main-text mt-2 pb-6 border-bottom">
              Tracker indicates any technology - e.g Cookies, unique identifiers, web beacons, embedded scripts, e-tags and fingerprinting - that enables the tracking of Users, for example by accessing or storing information on the User’s device.
            </p>
            <p class="main-text font-weight-bold mt-6">
              Legal information
            </p>
            <p class="main-text mt-2">
              This privacy statement has been prepared based on provisions of multiple legislations, including Art. 13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).
            </p>
            <p class="main-text mt-2 pb-6 border-bottom">
              This privacy policy relates solely to this Application, if not stated otherwise within this document.
            </p>
            <p class="footer-text mt-6">
              Latest update: March 24, 2022
            </p>
            <p class="footer-text font-weight-bold mt-2">
              United Metas hosts this content and only collects the Personal Data strictly necessary for it to be provided.
            </p>
          </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PrivacyPolicy",
}
</script>

<style lang="scss" scoped>
.privacy-police {
  background-color: #fff;
}
.content-section {
  max-width: 848px;
  margin: 0 auto;
  font-family: inherit;
}
.borders {
  border-top: 1px dotted #DFDFDF;
  border-bottom: 1px dotted #DFDFDF;
}
.border-bottom {
  border-bottom: 1px dotted #DFDFDF;
}
.chapter-head {
  font-size: 17px;
  font-weight: bold;
  line-height: 21px;
  color: #3F3F3F;
}
.page-title {
  font-size: 19px;
  font-weight: normal;
  line-height: 23px;
  margin-bottom: 5px;
  color: #59636D;
  span {
    font-weight: bold;
    color: #3F3F3F;
  }
}
.main-text {
  line-height: 19px;
  font-size: 13px;
  color: #6B6B6B;
}
.small-head {
  font-size: 13px;
  line-height: 19px;
  font-weight: bold;
  color: #3F3F3F;
}
.summary-text-block {
  p {
    max-width: 315px;
  }
}
.further-information {
  p {
    max-width: 700px;
  }
}
.full-summary {
  .text-block {
    p {
      max-width: 700px;
    }
  }
}
.footer-text {
  font-size: 11px;
  line-height: 19px;
  color: #6B6B6B;
}
@media only screen and (max-width: $sm) {
  .summary-text-block {
    p {
      max-width: 320px;
    }
  }
  .further-information {
    p {
      max-width: 320px;
    }
  }
  .full-summary {
    .text-block {
      p {
        max-width: 320px;
      }
    }
  }
}
</style>
