<template>
  <div
    :class="[
      'terms-conditions',
      isMobile ? 'mt-15 pt-10 pb-10' : 'mt-25 pt-13 pb-15'
    ]"
  >
    <div class="content-section px-6">
      <div class="chapter pb-6 border-bottom">
        <h2 class="main-head text-center pb-13">
          Terms and Conditions of United Metas
        </h2>
        <p class="main-text">
          These Terms govern
        </p>
        <ul class="default-list mt-1">
          <li class="main-text ml-8">
            - the use of this Application, and,
          </li>
          <li class="main-text ml-8">
            - any other related Agreement or legal relationship with the Owner
          </li>
        </ul>
        <p class="main-text">
          in a legally binding way. Capitalized words are defined in the relevant dedicated section of this document.
        </p>
        <p class="main-text mt-2">
          The User must read this document carefully.
        </p>
      </div>
      <div class="contact-information">
        <h4 class="chapter-head py-6">
          Company details
        </h4>
        <div class="contact-info d-flex gap-4 mb-6">
          <VImg
            src="policyIcon4.png"
            width="24"
            height="24"
          />
          <div class="text-block">
            <span class="small-head">
              Owner and Data Controller
            </span>
            <span class="d-block main-text">
              United Metas DMCC193056
            </span>
            <br>
            <span class="d-block main-text">
              Unit No: 801
            </span>
            <span class="d-block main-text">
              Jumeirah Lakes Towers
            </span>
            <span class="d-block main-text">
              Dubai
            </span>
            <span class="d-block main-text mb-3">
              UAE
            </span>
          </div>
        </div>
      </div>
      <div class="chapter mt-2 pb-6 border-bottom">
        <h2 class="secondary-head pt-10">
          What the User should know at a glance
        </h2>
        <br>
        <ul>
          <li class="main-text ml-8">
            - Please note that some provisions in these Terms may only apply to certain categories of Users. In particular, certain provisions may only apply to Consumers or to those Users that do not qualify as Consumers. Such limitations are always explicitly mentioned within each affected clause. In the absence of any such mention, clauses apply to all Users.
          </li>
        </ul>
      </div>
      <div class="chapter pt-13">
        <h3 class="secondary-head">
          How this Application works
        </h3>
        <h3 class="secondary-head pt-13">
          TERMS OF USE
        </h3>
        <br>
        <p class="main-text">
          Unless otherwise specified, the terms of use detailed in this section apply generally when using this Application.
        </p>
        <p class="main-text mt-2">
          Single or additional conditions of use or access may apply in specific scenarios and in such cases are additionally indicated within this document.
        </p>
        <p class="main-text mt-2">
          By using this Application, Users confirm to meet the following requirements:
        </p>
        <ul>
          <li class="main-text ml-8 mt-2">
            - There are no restrictions for Users in terms of being Consumers or Business Users;
          </li>
          <li class="main-text ml-8 mt-2">
            - Users must be recognized as adult by applicable law;
          </li>
          <li class="main-text ml-8 mt-2">
            - Minors may only use this Application under parental or adult supervision;
          </li>
        </ul>
        <h3 class="chapter-head pt-13">
          Account registration
        </h3>
        <p class="main-text mt-2">
          To use the Service Users must register or create a User account, providing all required data or information in a complete and truthful manner.
        </p>
        <p class="main-text">
          Failure to do so will cause unavailability of the Service.
        </p>
        <p class="main-text mt-2">
          Users are responsible for keeping their login credentials confidential and safe. For this reason, Users are also required to choose passwords that meet the highest standards of strength permitted by this Application.
        </p>
        <p class="main-text mt-2">
          By registering, Users agree to be fully responsible for all activities that occur under their username and password.
        </p>
        <p class="main-text mt-2">
          ByUsers are required to immediately and unambiguously inform the Owner via the contact details indicated in this document, if they think their personal information, including but not limited to User accounts, access credentials or personal data, have been violated, unduly disclosed or stolen.
        </p>
        <h3 class="small-head pt-9">
          Account termination
        </h3>
        <p class="main-text mt-2">
          Users can terminate their account and stop using the Service at any time by doing the following:
        </p>
        <ul class="mt-2">
          <li class="main-text ml-8">
            - By directly contacting the Owner at the contact details provided in this document.
          </li>
        </ul>
        <h4 class="small-head pt-9">
          Account suspension and deletion
        </h4>
        <p class="main-text mt-2">
          The Owner reserves the right, at its sole discretion, to suspend or delete at any time and without notice, User accounts which it deems inappropriate, offensive or in violation of these Terms
        </p>
        <p class="main-text mt-2">
          The suspension or deletion of User accounts shall not entitle Users to any claims for compensation, damages or reimbursement.
        </p>
        <p class="main-text mt-2">
          The suspension or deletion of accounts due to causes attributable to the User does not exempt the User from paying any applicable fees or prices.
        </p>
        <h3 class="chapter-head pt-13">
          Content on this Application
        </h3>
        <p class="main-text mt-2">
          Unless where otherwise specified or clearly recognizable, all content available on this Application is owned or provided by the Owner or its licensors.
        </p>
        <p class="main-text mt-2">
          The Owner undertakes its utmost effort to ensure that the content provided on this Application infringes no applicable legal provisions or third-party rights. However, it may not always be possible to achieve such a result.
        </p>
        <p class="main-text mt-2">
          In such cases, without prejudice to any legal prerogatives of Users to enforce their rights, Users are kindly asked to preferably report related complaints using the contact details provided in this document.
        </p>
        <h4 class="small-head mt-9">
          Rights regarding content on this Application - All rights reserved
        </h4>
        <p class="main-text mt-2">
          The Owner holds and reserves all intellectual property rights for any such content.
        </p>
        <p class="main-text mt-2">
          Users may not therefore use such content in any way that is not necessary or implicit in the proper use of the Service.
        </p>
        <p class="main-text mt-2">
          In particular, but without limitation, Users may not copy, download, share (beyond the limits set forth below), modify, translate, transform, publish, transmit, sell, sublicense, edit, transfer/assign to third parties or create derivative works from the content available on this Application, nor allow any third party to do so through the User or their device, even without the User's knowledge.
        </p>
        <p class="main-text mt-2">
          Where explicitly stated on this Application, the User may download, copy and/or share some content available through this Application for its sole personal and non-commercial use and provided that the copyright attributions and all the other attributions requested by the Owner are correctly implemented.
        </p>
        <p class="main-text mt-2">
          Any applicable statutory limitation or exception to copyright shall stay unaffected.
        </p>
        <h2 class="chapter-head pt-13">
          Access to external resources
        </h2>
        <p class="main-text mt-2">
          Through this Application Users may have access to external resources provided by third parties. Users acknowledge and accept that the Owner has no control over such resources and is therefore not responsible for their content and availability.
        </p>
        <p class="main-text mt-2">
          Conditions applicable to any resources provided by third parties, including those applicable to any possible grant of rights in content, result from each such third parties’ terms and conditions or, in the absence of those, applicable statutory law.
        </p>
        <h2 class="chapter-head pt-13">
          Acceptable use
        </h2>
        <p class="main-text mt-2">
          This Application and the Service may only be used within the scope of what they are provided for, under these Terms and applicable law.
        </p>
        <p class="main-text mt-2">
          Users are solely responsible for making sure that their use of this Application and/or the Service violates no applicable law, regulations or third-party rights.
        </p>
        <p class="main-text mt-2">
          Therefore, the Owner reserves the right to take any appropriate measure to protect its legitimate interests including by denying Users access to this Application or the Service, terminating contracts, reporting any misconduct performed through this Application or the Service to the competent authorities – such as judicial or administrative authorities - whenever Users engage or are suspected to engage in any of the following activities:
        </p>
        <ul class="mt-2">
          <li class="main-text ml-8">
            - violate laws, regulations and/or these Terms;
          </li>
          <li class="main-text ml-8">
            - infringe any third-party rights;
          </li>
          <li class="main-text ml-8">
            - considerably impair the Owner’s legitimate interests;
          </li>
          <li class="main-text ml-8">
            - offend the Owner or any third party.
          </li>
        </ul>
      </div>
      <div class="chapter pt-13">
        <h2 class="secondary-head">
          TERMS AND CONDITIONS OF SALE
        </h2>
        <h3 class="chapter-head pt-13">
          Paid Products
        </h3>
        <p class="main-text mt-2">
          Some of the Products provided on this Application, as part of the Service, are provided on the basis of payment.
        </p>
        <p class="main-text mt-2">
          The fees, duration and conditions applicable to the purchase of such Products are described below and in the dedicated sections of this Application
        </p>
        <h3 class="chapter-head pt-13">
          Product description
        </h3>
        <p class="main-text mt-2">
          Prices, descriptions or availability of Products are outlined in the respective sections of this Application and are subject to change without notice.
        </p>
        <p class="main-text mt-2">
          While Products on this Application are presented with the greatest accuracy technically possible, representation on this Application through any means (including, as the case may be, graphic material, images, colors, sounds) is for reference only and implies no warranty as to the characteristics of the purchased Product.
        </p>
        <p class="main-text mt-2">
          The characteristics of the chosen Product will be outlined during the purchasing process.
        </p>
        <h3 class="chapter-head pt-13">
          Order submission
        </h3>
        <p class="main-text mt-2">
          When the User submits an order, the following applies:
        </p>
        <ul class="mt-2">
          <li class="main-text ml-8">
            - The submission of an order determines contract conclusion and therefore creates for the User the obligation to pay the price, taxes and possible further fees and expenses, as specified on the order page.
          </li>
          <li class="main-text ml-8 mt-1">
            - In case the purchased Product requires active input from the User, such as the provision of personal information or data, specifications or special wishes, the order submission creates an obligation for the User to cooperate accordingly.
          </li>
          <li class="main-text ml-8 mt-1">
            - Upon submission of the order, Users will receive a receipt confirming that the order has been received.
          </li>
        </ul>
        <p class="main-text mt-2">
          All notifications related to the described purchasing process shall be sent to the email address provided by the User for such purposes.
        </p>
        <h3 class="chapter-head pt-13">
          Prices
        </h3>
        <p class="main-text mt-2">
          Users are informed during the purchasing process and before order submission, about any fees, taxes and costs (including, if any, delivery costs) that they will be charged.
        </p>
        <p class="main-text mt-2">
          Prices on this Application are displayed:
        </p>
        <ul class="mt-2">
          <li class="main-text mt-2">
            - either exclusive or inclusive of any applicable fees, taxes and costs, depending on the section the User is browsing.
          </li>
        </ul>
        <h3 class="chapter-head pt-13">
          Methods of payment
        </h3>
        <p class="main-text mt-2">
          Information related to accepted payment methods are made available during the purchasing process.
        </p>
        <p class="main-text mt-2">
          Some payment methods may only be available subject to additional conditions or fees. In such cases related information can be found in the dedicated section of this Application.
        </p>
        <p class="main-text mt-2">
          All payments are independently processed through third-party services. Therefore, this Application does not collect any payment information – such as credit card details – but only receives a notification once the payment has been successfully completed.
        </p>
        <p class="main-text mt-2">
          If a payment through the available methods fails or is refused by the payment service provider, the Owner shall be under no obligation to fulfil the purchase order. Any possible costs or fees resulting from the failed or refused payment shall be borne by the User.
        </p>
      </div>
      <div class="chapter pt-13">
        <h2 class="secondary-head">
          Liability and indemnification
        </h2>
        <h3 class="chapter-head pt-13">
          EU Users
        </h3>
        <h4 class="small-head pt-9">
          Indemnification
        </h4>
        <p class="main-text mt-2">
          The User agrees to indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners and employees harmless from and against any claim or demand ⁠— including but not limited to lawyer's fees and costs ⁠— made by any third party due to or in relation with any culpable use of or connection to the Service, violation of these Terms, infringement of any third-party rights or statutory provision by the User or its affiliates, officers, directors, agents, co-branders, partners and employees to the extent allowed by applicable law.
        </p>
        <h4 class="small-head pt-9">
          Limitation of liability
        </h4>
        <p class="main-text mt-2">
          Unless otherwise explicitly stated and without prejudice to applicable statutory product liability provisions, Users shall have no right to claim damages against the Owner (or any natural or legal person acting on its behalf).
        </p>
        <p class="main-text mt-2">
          This does not apply to damages to life, health or physical integrity, damages resulting from the breach of an essential contractual obligation such as any obligation strictly necessary to achieve the purpose of the contract, and/or damages resulting from intent or gross negligence, as long as this Application has been appropriately and correctly used by the User.
        </p>
        <p class="main-text mt-2">
          Unless damages have been caused by way of intent or gross negligence, or they affect life, health or physical integrity, the Owner shall only be liable to the extent of typical and foreseeable damages at the moment the contract was entered into.
        </p>
        <h3 class="chapter-head pt-13">
          EU Users
        </h3>
        <h4 class="small-head pt-9">
          Disclaimer of Warranties
        </h4>
        <p class="main-text font-weight-bold mt-2">
          This Application is provided strictly on an “as is” and “as available” basis. Use of the Service is at Users’ own risk. To the maximum extent permitted by applicable law, the Owner expressly disclaims all conditions, representations, and warranties — whether express, implied, statutory or otherwise, including, but not limited to, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third-party rights. No advice or information, whether oral or written, obtained by user from owner or through the Service will create any warranty not expressly stated herein.
        </p>
        <p class="main-text font-weight-bold mt-2">
          Without limiting the foregoing, the Owner, its subsidiaries, affiliates, licensors, officers, directors, agents, co-branders, partners, suppliers and employees do not warrant that the content is accurate, reliable or correct; that the Service will meet Users’ requirements; that the Service will be available at any particular time or location, uninterrupted or secure; that any defects or errors will be corrected; or that the Service is free of viruses or other harmful components. Any content downloaded or otherwise obtained through the use of the Service is downloaded at users own risk and users shall be solely responsible for any damage to Users’ computer system or mobile device or loss of data that results from such download or Users’ use of the Service.
        </p>
        <p class="main-text font-weight-bold mt-2">
          The Owner does not warrant, endorse, guarantee, or assume responsibility for any product or service advertised or offered by a third party through the Service or any hyperlinked website or service, and the Owner shall not be a party to or in any way monitor any transaction between Users and third-party providers of products or services.
        </p>
        <p class="main-text font-weight-bold mt-2">
          The Service may become inaccessible or it may not function properly with Users’ web browser, mobile device, and/or operating system. The owner cannot be held liable for any perceived or actual damages arising from Service content, operation, or use of this Service.
        </p>
        <p class="main-text font-weight-bold mt-2">
          Federal law, some states, and other jurisdictions, do not allow the exclusion and limitations of certain implied warranties. The above exclusions may not apply to Users. This Agreement gives Users specific legal rights, and Users may also have other rights which vary from state to state. The disclaimers and exclusions under this agreement shall not apply to the extent prohibited by applicable law.
        </p>
        <h4 class="small-head pt-9">
          Limitations of liability
        </h4>
        <p class="main-text font-weight-bold mt-2">
          To the maximum extent permitted by applicable law, in no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for
        </p>
        <ul class="mt-2">
          <li class="main-text font-weight-bold mt-1 ml-8">
            - any indirect, punitive, incidental, special, consequential or exemplary damages, including without limitation damages for loss of profits, goodwill, use, data or other intangible losses, arising out of or relating to the use of, or inability to use, the Service; and
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - any damage, loss or injury resulting from hacking, tampering or other unauthorized access or use of the Service or User account or the information contained therein;
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - any errors, mistakes, or inaccuracies of content;
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - personal injury or property damage, of any nature whatsoever, resulting from User access to or use of the Service;
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - any unauthorized access to or use of the Owner’s secure servers and/or any and all personal information stored therein;
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - any interruption or cessation of transmission to or from the Service;
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Service;
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Service; and/or
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - the defamatory, offensive, or illegal conduct of any User or third party. In no event shall the Owner, and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees be liable for any claims, proceedings, liabilities, obligations, damages, losses or costs in an amount exceeding the amount paid by User to the Owner hereunder in the preceding 12 months, or the period of duration of this agreement between the Owner and User, whichever is shorter.
          </li>
        </ul>
        <p class="main-text font-weight-bold mt-2">
          This limitation of liability section shall apply to the fullest extent permitted by law in the applicable jurisdiction whether the alleged liability is based on contract, tort, negligence, strict liability, or any other basis, even if company has been advised of the possibility of such damage.
        </p>
        <p class="main-text font-weight-bold mt-2">
          Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, therefore the above limitations or exclusions may not apply to User. The terms give User specific legal rights, and User may also have other rights which vary from jurisdiction to jurisdiction. The disclaimers, exclusions, and limitations of liability under the terms shall not apply to the extent prohibited by applicable law.
        </p>
        <h4 class="small-head pt-9">
          Indemnification
        </h4>
        <p class="main-text font-weight-bold mt-2">
          The User agrees to defend, indemnify and hold the Owner and its subsidiaries, affiliates, officers, directors, agents, co-branders, partners, suppliers and employees harmless from and against any and all claims or demands, damages, obligations, losses, liabilities, costs or debt, and expenses, including, but not limited to, legal fees and expenses, arising from
        </p>
        <ul class="mt-2">
          <li class="main-text font-weight-bold mt-1 ml-8">
            - User’s use of and access to the Service, including any data or content transmitted or received by User;
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - User’s violation of these terms, including, but not limited to, User’s breach of any of the representations and warranties set forth in these terms;
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - User’s violation of any third-party rights, including, but not limited to, any right of privacy or intellectual property rights;
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - User’s violation of any statutory law, rule, or regulation;
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - any content that is submitted from User’s account, including third party access with User’s unique username, password or other security measure, if applicable, including, but not limited to, misleading, false, or inaccurate information;
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - User’s wilful misconduct; or
          </li>
          <li class="main-text font-weight-bold mt-1 ml-8">
            - statutory provision by User or its affiliates, officers, directors, agents, co-branders, partners, suppliers and employees to the extent allowed by applicable la
          </li>
        </ul>
      </div>
      <div class="chapter pt-13 pb-6 border-bottom">
        <h2 class="secondary-head">
          Common provisions
        </h2>
        <h3 class="chapter-head pt-9">
          No Waiver
        </h3>
        <p class="main-text mt-2">
          The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision. No waiver shall be considered a further or continuing waiver of such term or any other term.
        </p>
        <h3 class="chapter-head pt-9">
          Service interruption
        </h3>
        <p class="main-text mt-2">
          To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.
        </p>
        <p class="main-text mt-2">
          Within the limits of law, the Owner may also decide to suspend or terminate the Service altogether. If the Service is terminated, the Owner will cooperate with Users to enable them to withdraw Personal Data or information in accordance with applicable law.
        </p>
        <p class="main-text mt-2">
          Additionally, the Service might not be available due to reasons outside the Owner’s reasonable control, such as “force majeure” (eg. labor actions, infrastructural breakdowns or blackouts etc).
        </p>
        <h3 class="chapter-head pt-9">
          Service reselling
        </h3>
        <p class="main-text mt-2">
          Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Application and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling programme.
        </p>
        <h3 class="chapter-head pt-9">
          Privacy policy
        </h3>
        <p class="main-text mt-2">
          To learn more about the use of their Personal Data, Users may refer to the privacy policy of this Application.
        </p>
        <h3 class="chapter-head pt-9">
          Intellectual property rights
        </h3>
        <p class="main-text mt-2">
          Without prejudice to any more specific provision of these Terms, any intellectual property rights, such as copyrights, trademark rights, patent rights and design rights related to this Application are the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties relating to intellectual property.
        </p>
        <p class="main-text mt-2">
          All trademarks — nominal or figurative — and all other marks, trade names, service marks, word marks, illustrations, images, or logos appearing in connection with this Application are, and remain, the exclusive property of the Owner or its licensors and are subject to the protection granted by applicable laws or international treaties related to intellectual property.
        </p>
        <h3 class="chapter-head pt-9">
          Changes to these Terms
        </h3>
        <p class="main-text mt-2">
          The Owner reserves the right to amend or otherwise modify these Terms at any time. In such cases, the Owner will appropriately inform the User of these changes.
        </p>
        <p class="main-text mt-2">
          Such changes will only affect the relationship with the User for the future.
        </p>
        <p class="main-text mt-2">
          The continued use of the Service will signify the User’s acceptance of the revised Terms. If Users do not wish to be bound by the changes, they must stop using the Service. Failure to accept the revised Terms, may entitle either party to terminate the Agreement.
        </p>
        <p class="main-text mt-2">
          The applicable previous version will govern the relationship prior to the User's acceptance. The User can obtain any previous version from the Owner.
        </p>
        <h3 class="chapter-head pt-9">
          Assignment of contract
        </h3>
        <p class="main-text mt-2">
          The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, taking the User’s legitimate interests into account. Provisions regarding changes of these Terms will apply accordingly.
        </p>
        <p class="main-text mt-2">
          Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.
        </p>
        <h3 class="chapter-head pt-9">
          Contacts
        </h3>
        <p class="main-text mt-2">
          All communications relating to the use of this Application must be sent using the contact information stated in this document.
        </p>
        <h3 class="chapter-head pt-9">
          Severability
        </h3>
        <p class="main-text mt-2">
          Should any provision of these Terms be deemed or become invalid or unenforceable under applicable law, the invalidity or unenforceability of such provision shall not affect the validity of the remaining provisions, which shall remain in full force and effect.
        </p>
        <h4 class="small-head pt-9">
          US Users
        </h4>
        <p class="main-text mt-2">
          Any such invalid or unenforceable provision will be interpreted, construed and reformed to the extent reasonably required to render it valid, enforceable and consistent with its original intent. These Terms constitute the entire Agreement between Users and the Owner with respect to the subject matter hereof, and supersede all other communications, including but not limited to all prior agreements, between the parties with respect to such subject matter. These Terms will be enforced to the fullest extent permitted by law.
        </p>
        <h4 class="small-head pt-9">
          US Users
        </h4>
        <p class="main-text mt-2">
          Should any provision of these Terms be or be deemed void, invalid or unenforceable, the parties shall do their best to find, in an amicable way, an agreement on valid and enforceable provisions thereby substituting the void, invalid or unenforceable parts.
        </p>
        <p class="main-text">
          In case of failure to do so, the void, invalid or unenforceable provisions shall be replaced by the applicable statutory provisions, if so permitted or stated under the applicable law.
        </p>
        <p class="main-text mt-2">
          Without prejudice to the above, the nullity, invalidity or the impossibility to enforce a particular provision of these Terms shall not nullify the entire Agreement, unless the severed provisions are essential to the Agreement, or of such importance that the parties would not have entered into the contract if they had known that the provision would not be valid, or in cases where the remaining provisions would translate into an unacceptable hardship on any of the parties.
        </p><h3 class="chapter-head pt-9">
          Venue of jurisdiction
        </h3>
        <p class="main-text mt-2">
          The exclusive competence to decide on any controversy resulting from or connected to these Terms lies with the courts of the place where the Owner is based, as displayed in the relevant section of this document.
        </p>
        <h4 class="small-head pt-9">
          Exception for European Consumers
        </h4>
        <p class="main-text mt-2">
          The above does not apply to any Users that qualify as European Consumers, nor to Consumers based in Switzerland, Norway or Iceland.
        </p>
      </div>
      <div class="chapter pt-13 pb-6 border-bottom">
        <h2 class="secondary-head">
          Definitions and legal references
        </h2>
        <p class="main-text mt-2">
          The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision. No waiver shall be considered a further or continuing waiver of such term or any other term.
        </p>
        <h4 class="small-head pt-9">
          This Application (or this Application)
        </h4>
        <p class="main-text mt-2">
          The property that enables the provision of the Service.
        </p>
        <h4 class="small-head pt-9">
          Agreement
        </h4>
        <p class="main-text mt-2">
          Any legally binding or contractual relationship between the Owner and the User, governed by these Terms.
        </p>
        <h4 class="small-head pt-9">
          Business User
        </h4>
        <p class="main-text mt-2">
          Any User that does not qualify as a Consumer.
        </p>
        <h4 class="small-head pt-9">
          European (or Europe)
        </h4>
        <p class="main-text mt-2">
          Applies where a User is physically present or has their registered offices within the EU, regardless of nationality.
        </p>
        <h4 class="small-head pt-9">
          Owner (or We)
        </h4>
        <p class="main-text mt-2">
          Indicates the natural person(s) or legal entity that provides this Application and/or the Service to Users.
        </p>
        <h4 class="small-head pt-9">
          Product
        </h4>
        <p class="main-text mt-2">
          A good or service available for purchase through this Application, such as e.g. physical goods, digital files, software, booking services etc.
        </p>
        <p class="main-text mt-2">
          The sale of Products may be part of the Service.
        </p>
        <h4 class="small-head pt-9">
          Service
        </h4>
        <p class="main-text mt-2">
          The service provided by this Application as described in these Terms and on this Application.
        </p>
        <h4 class="small-head pt-9">
          Terms
        </h4>
        <p class="main-text mt-2">
          All provisions applicable to the use of this Application and/or the Service as described in this document, including any other related documents or agreements, and as updated from time to time.
        </p>
        <h4 class="small-head pt-9">
          User (or You)
        </h4>
        <p class="main-text mt-2">
          Indicates any natural person or legal entity using this Application.
        </p>
        <h4 class="small-head pt-9">
          Consumer
        </h4>
        <p class="main-text mt-2">
          Any User qualifying as a natural person who accesses goods or services for personal use, or more generally, acts for purposes outside their trade, business, craft or profession.
        </p>
      </div>
      <p class="main-text mt-6">
        Latest update: March 24, 2022
      </p>
      <p class="secondary-text mt-2">
        United Metas hosts this content and only collects the Personal Data strictly necessary for it to be provided.
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: "TermsAndConditions",
}
</script>

<style lang="scss" scoped>
.terms-conditions {
  background-color: #fff;
}
.main-head {
  font-size: 30px;
  color: #141414;
  line-height: 1.6;
}
.secondary-head {
  font-size: 24px;
  line-height: 1.6;
  color: #141414;
}
.content-section {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  font-family: inherit;
}
.borders {
  border-top: 1px dotted #DFDFDF;
  border-bottom: 1px dotted #DFDFDF;
}
.border-bottom {
  border-bottom: 1px dotted #DFDFDF;
}
.chapter-head {
  font-size: 20px;
  font-weight: bold;
  line-height: 21px;
  color: #3F3F3F;
}
.main-text {
  font-size: 14px;
  line-height: 1.6;
  color: #666;
}
.secondary-text {
  font-size: 13px;
  line-height: 1.6;
  color: #666;
}
.small-head {
  font-size: 16px;
  line-height: 1.6;
  font-weight: bold;
  color: #3F3F3F;
}
</style>
