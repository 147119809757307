<template>
  <div id="mission" class="mission-section sections-padding-y menu-anchor">
    <div class="container">
      <div class="content-type-2 white">
        <div 
          :class="[
            'd-flex',
            { 'flex-column align-center': !isTablet }
          ]"
        >
          <div :class="{ 'mr-10': isTablet }">
            <h3 class="title-section text-body-2 light-blue--text font-weight-bold">
              {{ $t('mission_title') }}
            </h3>
            <p class="text-body-5 black-text">
              {{ $t('mission_paragraph_1') }}
            </p>
            <p class="text-body-5 black-text">
              {{ $t('mission_paragraph_2') }}
            </p>
            <p class="text-body-5 black-text">
              {{ $t('mission_paragraph_3') }}
            </p>
            <p class="text-body-5 black-text">
              {{ $t('mission_paragraph_4') }}
            </p>
          </div>
          <div>
            <VImg
              :src="isMobile ? 'missionImageMobile.png' : 'missionImage.png'"
              :width="isMobile ? '280' : '441'"
              :height="isMobile ? '315' : '497'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./mission-section"></script>

<style lang="scss" scoped>
.text-body-5 {
  line-height: 21px;
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: $md) {
  .text-body-5:last-of-type {
    margin-bottom: 30px;
  }
}
</style>