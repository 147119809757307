<template>
<div class="v-image"
  :style="{
    backgroundImage: `url(${require('@/assets/images/' + src)})`,
    width: width === '100%' ? '100%' : (width + 'px'),
    height: height + 'px'
  }"
>
</div>
</template>

<script src="./v-img.js">
</script>

<style lang="scss" scoped>
.v-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
