import store from '@/store'

class ApiBase {
  static baseUrl () {
    return process.env.VUE_APP_API_URL
  }

  static setToStore (self, name, data, callBack) {
    store.dispatch(name, data)
    if (callBack) {
      callBack()
    }
  }
}

export default ApiBase