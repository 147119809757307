export default {
  name: "VBtn",
  props: {
    width: {
      type: [String, Number],
    },
    height: {
      type: [String, Number],
      default: 36
    },
    block: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    uppercase: {
      type: Boolean,
      default: true
    },
    size: {
      type: [String, Number],
      default: 14
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
};
