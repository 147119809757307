export default {
  name: 'ExplanationSection',
  data: () => ({
    cards: [
      {
        title: 'explanation_card_1_title',
        content: 'explanation_card_1_content',
        icon: 'explanationIcon1.svg'
      },
      {
        title: 'explanation_card_2_title',
        content: 'explanation_card_2_content',
        icon: 'explanationIcon2.svg'
      },
      {
        title: 'explanation_card_3_title',
        content: 'explanation_card_3_content',
        icon: 'explanationIcon3.svg'
      }
    ]
  })
}