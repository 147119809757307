import RouterComponent from '@/components/router/Router'
import Home from '@/pages/Home.vue'

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
  ],
}
