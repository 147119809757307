import store from '@/store'
import breakpoints from '@/assets/scss/export_vars.scss'
import { notify } from '@/helpers/notifyHelper'

export default {
  data: () => ({
    windowWidth: 0,
  }),
  mounted () {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  computed: {
    isMobile () {
      return this.windowWidth <= parseInt(breakpoints.sm)
    },
    isTablet () {
      return this.windowWidth >= parseInt(breakpoints.md)
    },
    breakpoints () {
      return breakpoints
    },
    state () {
			return store.getters
		},
  },
  methods: {
    notify (type, message) {
      notify(this, type, message)
    }
  }
}
