<template>
  <div id="preview" class="preview-section relative menu-anchor">
    <div class="container">
      <div class="content">
        <div class="planets">
          <VImg
            class="absolute planet"
            src="previewPlanet1.svg"
            width="179"
            height="179"
          />
          <VImg
            class="absolute planet"
            :src="isMobile ? 'previewPlanet2Mobile.svg' : 'previewPlanet2.svg'"
            :width="isMobile ? '104' : '180'"
            :height="isMobile ? '135' : '180'"
          />
          <VImg
            class="absolute planet"
            :src="isMobile ? 'previewPlanet3Mobile.svg' : 'previewPlanet3.svg'"
            :width="isMobile ? '230' : '298'"
            :height="isMobile ? '211' : '162'"
          />
        </div>
        <div>
          <div class="relative">
            <h1 class="main-title font-weight-800 text-body-0 relative">
              {{ $t('preview_mainTitle') }}
            </h1>
            <h1 class="title-shadow font-weight-800 text-body-0 absolute">
              {{ $t('preview_mainTitle') }}
            </h1>
          </div>
          <h2 class="sub-title text-body-1 white--text font-weight-600">
            {{ $t('preview_subTitle') }}
          </h2>
        </div>
        <a href="https://app.unitedmetas.io/login">
        <VBtn
          class="btn light-blue b-radius-50"
          width="220"
          height="42"
          size="16"

        >
          {{ $t('preview_btn_login') }}
        </VBtn>
        </a>
        <a href="https://app.unitedmetas.io/register">
        <VBtn
          class="btn light-blue b-radius-50"
          width="220"
          height="42"
          size="16"
        >
          {{ $t('preview_btn_register') }}
        </VBtn>
        </a>
      </div>
    </div>
  </div>
</template>

<script src="./preview-section"></script>

<style lang="scss" scoped>
.preview-section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(~@/assets/images/previewBg.png);
}
.content {
  padding-top: 264px;
  padding-bottom: 433px;
}
.main-title {
  color: transparent;
  -webkit-text-stroke: 3px white;
  margin-bottom: 10px;
  z-index: 2;
}
.title-shadow {
  left:0%;
  top: 0%;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.8);
  color: white;
  mix-blend-mode: multiply;
}
.sub-title {
  margin-bottom: 100px;
}
.btn {
  font-weight: 400 !important;
  line-height: 19px !important;
  transition: 0.2s linear;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
  &:first-of-type {
    margin-right: 22px;
  }
  &:hover {
    background-color: $blue !important;
  }
}
.planet {
  &:first-of-type {
    left: 21.3%;
    top: 47px;
  }
  &:nth-of-type(2) {
    left: 37.4%;
    top: 190px;
  }
  &:last-of-type {
    right: 23%;
    top: 24px;
  }
}
@media only screen and (max-width: $sm) {
  .content {
    padding-top: 150px;
    padding-bottom: 213px;
  }
  .main-title {
    -webkit-text-stroke: 2px white;
  }
  .sub-title {
    margin-bottom: 50px;
  }
  .btn {
    display: block;
    margin: 0 auto;
    &:first-of-type {
      margin: 0px auto 20px;
    }
  }
  .planet {
    &:first-of-type {
      display: none;
    }
    &:nth-of-type(2) {
      left: 0%;
      top: 12px;
    }
    &:last-of-type {
      right: 5%;
      top: 58px;
    }
  }
}
@media only screen and (max-width: $xs) {
  .preview-section {
    background-image: url(~@/assets/images/previewBgMobile.png);
  }
}
</style>
